<template>
  <ModalDrawer
    visible
    :title="t('paymentDetails')"
    hide-footer
    @hide="hide"
  >
    <BookingPriceBreakdownReceipt
      :financial="financial"
      :rv-snapshot="rvSnapshot"
      :delivery-distance="deliveryDistance"
      :hide-receipt-footer="hideReceiptFooter"
    />
  </ModalDrawer>
</template>

<script setup lang="ts">
import type { FinancialCommon, RvSnapshot } from '~/types'

defineProps<{
  financial: FinancialCommon
  rvSnapshot: RvSnapshot
  deliveryDistance?: number
  hideReceiptFooter?: boolean
}>()

const emit = defineEmits<{
  hide: []
}>()

const { t } = useI18n()

function hide() {
  emit('hide')
}
</script>

<i18n lang="json">
  {
    "en": {
      "paymentDetails": "Payment details"
    },
    "fr": {
      "paymentDetails": "Détails de paiement"
    }
  }
</i18n>
